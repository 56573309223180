import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AlertController, NavController } from '@ionic/angular';
import { CommonService } from '../common.service'

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  private alertController: AlertController;
  isCollapsed: boolean = false;
  logoutBtn: any = false;
  date = new Date();
  constructor(private afAuth: AngularFireAuth, public navCtrl: NavController, private commonService: CommonService) { }

  ngOnInit() {
    this.commonService.sidebar.subscribe(() => {
      this.isCollapsed = !this.isCollapsed
    });
  }

  toggleSidebar() {
    this.commonService.showSidenav();
  }

  async signOut() {
    return this.afAuth.signOut().then(() => {
      this.navCtrl.navigateRoot(['/auth/login']);
    });
  }

  logoutModal() {
    this.logoutBtn = !this.logoutBtn;
  }
}

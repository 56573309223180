import { Injectable, EventEmitter, Output} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  @Output() sidebar = new EventEmitter<string>();
  constructor() { }

  showSidenav() {
    this.sidebar.emit();
  }
}

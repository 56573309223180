/* eslint-disable @typescript-eslint/member-ordering */
import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Router } from '@angular/router';
import { CommonService } from '../common.service'

@Component({
  selector: 'app-left-nav',
  templateUrl: './left-nav.component.html',
  styleUrls: ['./left-nav.component.scss'],
})
export class LeftNavComponent implements OnInit {
  isCollapsed: boolean = false;
  isClicked: any = false;
  loginEmail: any;
  constructor(private router: Router, private afAuth: AngularFireAuth, private commonService: CommonService) {

  }

  ngOnInit() {
    this.commonService.sidebar.subscribe(() => {
      this.isCollapsed = !this.isCollapsed
    });
    this.afAuth.authState.subscribe((user: any) => {
      this.loginEmail = user.email;
    });
  }

  getRoute() {
    if (window.location.href.includes('home')) {
      return 'home';
    }
    if (window.location.href.includes('user/user-list')) {
      return 'user';
    }
    if (window.location.href.includes('/transaction')) {
      return 'transaction';
    }
    if (window.location.href.includes('/monitor')) {
      return 'monitor';
    } if (window.location.href.includes('/post-report')) {
      return 'post-report';
    }
    if (window.location.href.includes('order')) {
      return 'order';
    }
    if (window.location.href.includes('brodcast')) {
      return 'brodcast';
    }
    if (window.location.href.includes('/setting')) {
      return 'setting';
    }
    if (window.location.href.includes('/content')) {
      return 'content';
    }
    if (window.location.href.includes('/supportticket')) {
      return 'supportticket';
    }
  }


  routeToComp(comp: any) {
    if (comp === 'home') {
      window.location.assign("/home");
      // this.router.navigate(['/home']);
    }

    if (comp === 'user') {
      window.location.assign("/user/user-list");
      // this.router.navigate(['/user/user-list']);
    }

    if (comp === 'transaction') {
      window.location.assign("/transaction");
      // this.router.navigate(['/transaction']);
    }

    if (comp === 'monitor') {
      window.location.assign("/monitor");
      // this.router.navigate(['/monitor']);
    }
    if (comp === 'post-report') {
      window.location.assign("/monitor/post-report");
      // this.router.navigate(['/monitor/post-report']);
    }
    if (comp === 'order') {
      window.location.assign("/order/list");
      // this.router.navigate(['/order/list']);
    }

    if (comp === 'brodcast') {
      window.location.assign("/message/brodcast");
      // this.router.navigate(['/message/brodcast']);
    }
    if (comp === 'ticket') {
      window.location.assign("/message/tticket");
      // this.router.navigate(['/message/tticket']);
    }
    if (comp === 'setting') {
      window.location.assign("/setting");
      // this.router.navigate(['/setting']);
    }
    if (comp === 'content') {
      window.location.assign("/content");
      // this.router.navigate(['/content']);
    }
    if (comp === 'supportticket') {
      window.location.assign("/supportticket");
      // this.router.navigate(['/supportticket']);
    }
  }
  clicked = false;

  toggleArrow() {
    this.clicked = !this.clicked;
  }
}





import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HeaderComponent } from './header/header.component';
import { LeftNavComponent } from './left-nav/left-nav.component';
import { IonicModule } from '@ionic/angular';

@NgModule({
  declarations: [
    HeaderComponent,
    LeftNavComponent
  ],
  imports: [
    CommonModule,IonicModule
  ],
  exports: [
    HeaderComponent,
    LeftNavComponent
  ]
})
export class AppSharedModule { }

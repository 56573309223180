import { Component } from '@angular/core';
import { Router, Event as RouterEvent, NavigationStart, NavigationEnd, NavigationCancel, NavigationError } from '@angular/router';
import { CommonService } from './app-shared/common.service'
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})

export class AppComponent {
  isCollapsed: boolean = false;
  app_shared_display: boolean = false;
  constructor(private router: Router, private commonService: CommonService) {
    router.events.subscribe((val: any) => {
      if (window.location.pathname === '/auth/login' || window.location.pathname === '/auth/signup' ||
        window.location.pathname === '/auth/reset-password' || window.location.pathname === '/privacy-policy' || window.location.pathname === '/return-refund-policy' || window.location.pathname === '/disclaimer' ||
        window.location.pathname === '/terms-and-conditions' || window.location.pathname === '/eula' || window.location.pathname === '/' || window.location.pathname === '/data-deletion' || 
        window.location.pathname === '/contact' || window.location.pathname === '/about-us') {
        this.app_shared_display = false;
      }
      else {
        this.app_shared_display = true;
      }
    });
  }

  ngOnInit() {
    this.commonService.sidebar.subscribe(() => {
      this.isCollapsed = !this.isCollapsed
    });
  }

  toggleSidebar() {
    this.commonService.showSidenav();
  }
}

